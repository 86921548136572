var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[(!_vm.compact)?_c('div',{key:"card-template",staticClass:"card-template"},[(_vm.item.title && _vm.item.title.length)?_c('div',{staticClass:"card-template__header pl-7 2xl:pl-14 text-base gap-3"},[_c('div',{staticClass:"label-wrapper gap-4 text-sm 2xl:text-base pb-2 2xl:pb-0"},_vm._l((_vm.item.title),function(label){return _c('div',{key:label.value,staticClass:"label gap-2 h-7 2xl:h-11 px-2.5 2xl:px-4 rounded-lg 2xl:rounded-xl w-28 2xl:w-36",style:({
            borderColor: label.color,
          })},[_c('div',{staticClass:"icon"},[_c(_vm.icons.label[label.type],{tag:"component",staticClass:"h-4 2xl:h-5"})],1),_c('span',{staticClass:"name gap-3"},[_vm._t("label",function(){return [_vm._v(_vm._s(label.value))]},{"label":label.value})],2)])}),0),_vm._t("label-after")],2):_vm._e(),_c('div',{staticClass:"card-template__body p-4 2xl:pt-6 2xl:pb-7 gap-4 2xl:gap-7 rounded-xl 2xl:rounded-3xl border border-box-stroke",attrs:{"id":"formAdmin"},on:{"click":_vm.handleScroll}},[_c('div',{staticClass:"icon w-7"},[_c(_vm.icons.activity[_vm.item.activity_type],{tag:"component",staticClass:"text-base-content-600 fill-current"})],1),_c('div',{staticClass:"content break-all"},[_c('div',{staticClass:"content__top gap-4 text-sm"},[(_vm.item.activity_type != 'tasks' && _vm.item.event_timekey)?_c('div',{staticClass:"property property--date"},[_vm._v(" "+_vm._s(_vm.FormateDateRow(_vm.item?.event_timekey))+" ")]):_vm._e(),_c('div',{staticClass:"actions gap-2"},[_vm._t("actions",function(){return [(_vm.item && _vm.item.actions)?[_vm._l((_vm.item.actions),function(btn){return [(_vm.buttons[btn] && !(btn === 'check' && _vm.item.email_type === 'OUTGOING'))?_c('div',{key:_vm.buttons[btn].id,staticClass:"actions__item-wrapper",attrs:{"name":`${_vm.item.id}__action-item__${_vm.buttons[btn].id}`},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleAction(_vm.buttons[btn])}}},[_c(_vm.buttons[btn].component,_vm._g(_vm._b({tag:"component",staticClass:"actions__item fill-current",class:[
                        'actions__item--' + _vm.buttons[btn].type,
                        _vm.buttons[btn].class,
                      ],attrs:{"title":_vm.buttons[btn].title,"is-ack":_vm.isAcknowledge(_vm.item),"item":_vm.item}},'component',_vm.buttons[btn].props,false),_vm.buttons[btn].events)),(_vm.buttons[btn].modal)?_c(_vm.buttons[btn].modal,{ref:_vm.buttons[btn].ref,refInFor:true,tag:"component",attrs:{"type":_vm.buttons[btn].type,"item":_vm.item,"isSequenceModalOpen":_vm.isSequenceModalOpen,"to":`${_vm.item.id}__action-item__${_vm.buttons[btn].id}`},on:{"update:isSequenceModalOpen":function($event){_vm.isSequenceModalOpen=$event},"update:is-sequence-modal-open":function($event){_vm.isSequenceModalOpen=$event}}}):_vm._e()],1):_vm._e()]})]:_vm._e()]})],2)]),(_vm.item.email_delivery_status == false)?_c('div',{staticClass:"flex bounceEmailCheck"},[(_vm.item.email_delivery_status == false)?_c(_vm.icons.activity['bounceEmail'],{tag:"component"}):_vm._e(),_c('span',{},[_vm._v("Bounce Back: "+_vm._s(_vm.item.email_status))])],1):_vm._e(),_c('div',{staticClass:"content__main text-xs 2xl:text-sm"},[_vm._t("content")],2)])])]):_c('div',{key:"compact-template",staticClass:"compact-template gap-2 p-4",class:{
      'compact-template--selected': !!_vm.selected,
    }},[(_vm.item.labels && _vm.item.labels.length)?_c('div',{staticClass:"compact-template__header text-base gap-3"},[_c('div',{staticClass:"label-wrapper"},_vm._l((_vm.item.labels),function(label){return _c('div',{key:label.title,staticClass:"label gap-2 p-0"},[_c('span',{staticClass:"name gap-3"},[_vm._t("label",function(){return [_vm._v(_vm._s(label.title))]},{"label":label.title})],2)])}),0),_vm._t("label-after")],2):_vm._e(),_c('div',{staticClass:"compact-template__body gap-7"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"content__main--compact gap-2"},[_vm._t("content-compact")],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }